import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor() {

    //let html = '<app-root></app-root>';
    //var myWindow = window.open(window.location.href, "MsgWindow", "width=200,height=100");
  }
}
