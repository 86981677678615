import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-matching',
  templateUrl: './app-no-matching.component.html'
})
export class NoMatchingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
