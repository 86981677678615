import { Component, OnInit } from '@angular/core';
import { ApplicationSettingService } from './services/application-setting.service';

@Component({
  selector: 'app-adminision',
  templateUrl: './adminision.component.html',
  styleUrls: ['./adminision.component.scss']
})
export class AdminisionComponent implements OnInit {

  constructor() { }
 
  ngOnInit() { 
  } 

}
